
.App {
  text-align: center;
}

#root{
  min-height: 100vh;
  background-color: #FFFBF9;
}

.intro {
  color: rgb(87, 87, 87);
  font-style: italic;
  margin-top: 10px;
  padding-left: 10px;
  margin-bottom: 0;
  border-left: #16697A dotted 1px;
}
.detail{
  color: #16697A;
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px;
}

.link{
  color: #16697A;
}
.row, .col {
  padding: 0 !important;
  margin: 0 !important;
}

h2 {
  font-family: 'Poppins';
  color: #16697A;
  text-transform: uppercase;
  border-bottom: 2px solid rgb(171, 145, 29);
  padding-bottom: 10px;
  margin-bottom: 20px !important;
}

h3 {
  color: rgb(101, 37, 93);
  font-size: 1.1em !important;
}

p {
  font-family: 'Roboto';
}

ul{
  /* list-style:none; */
  list-style-image: url('./assets/code.svg') ;
}
.descList{
  list-style-image: none;
  list-style:square;
  padding: 0;
  font-size: 14px;
}
.card {
  border: dotted 2px #65255D !important;
  
}

.tech{
  font-size: 0.9em ;
}

.submitBtn{
  background-color: #16697A;
  color: #FFFBF9;
  padding: 8px;
  margin-top: 30px;
  border: none;
  border-radius: 15px;
}

.submitBtn:disabled{
  background-color: grey;
}

#overlay-root{
  position:absolute;
  height: 100vh;
  display: flex;
  align-items: center;
}

@media screen and (min-height:600px) {
 .contactForm{
  margin-top: 50px;
 } 
}

@media screen and (min-height:760px) {
  .contactForm{
   margin-top: 100px;
  } 
 }

@media screen and (min-width: 992px) {
  #root {
    height: 100vh;
    display: flex;
  }

  .scroll {
    height: 100vh;
    overflow-y: auto;
    /* border-top: #FFFBF9 solid 30px; */
    /* border-bottom: #FFFBF9 solid 30px; */
  }

  .submitBtn{
    width: 300px;
    align-self: center;
  }
}